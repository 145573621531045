import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// import { MatDialogModule } from '@angular/material/dialog';
// import { MatExpansionModule } from '@angular/material/expansion';

import { AppUserModule } from './app-user/app-user.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { LoaderComponent } from './loader/loader.component';
import { ValidationService } from 'src/shared/validation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { API_BASE_URL, SessionServiceProxy } from 'src/shared/service-proxies/service-proxies';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AcceptTourComponent } from './app-user/tour/my-tours/accept-tour/accept-tour.component';
import { RejectTourComponent } from './app-user/tour/my-tours/reject-tour/reject-tour.component';
import { SentemailModule } from './sentemail/sentemail.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ScheduleModule, RecurrenceEditorModule } from '@syncfusion/ej2-angular-schedule';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { environment } from 'src/environments/environment';
import { SessionExpirationDialogComponent } from './login/session-expiration-dialog/session-expiration-dialog.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    // DashboardHeaderComponent,
    LoaderComponent,
    AppComponent,
    SessionExpirationDialogComponent,
   

  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // MatExpansionModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatTableModule,
    MatNativeDateModule,
    MatCardModule,
    MatSelectModule,NgxMaterialTimepickerModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatDialogModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    MatAutocompleteModule,
    DropDownListModule,
    DateTimePickerModule,
    ScheduleModule,
    RecurrenceEditorModule,
    MatSortModule,
    GoogleMapsModule,
    TranslateModule,
    MatPaginatorModule,
    SharedModule,
    MatButtonModule,
    MatSnackBarModule,
    SentemailModule,
    AppRoutingModule,
    MatPaginatorModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      // defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    })
    // TourModule,
    // AppUserModule
  ],
  providers: [
    { provide: 'API_BASE_URL', useFactory: getRemoteServiceBaseUrl },
    { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
    ValidationService, BsModalService, SessionServiceProxy,
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function getRemoteServiceBaseUrl(): string {
  return environment.BaseAPIUrlTeamA; //"https://localhost:44311";//environment.nSwagUrl;
}
