import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class AdminsService {
  baseUrl: any;


  constructor(private httpService: HttpClientService) { }

  GetUserByEmail = <T>(emailId: number) => this.httpService.get<T>(`User/Email/${emailId}`);

  // Get user by role id
  GetUsersByRole = <T>(roleId: number) => this.httpService.get<T>(`User/Role/${roleId}`);

  // Get officeManagers by logged user
  GetOfficeManagersByUser = <T>() => this.httpService.get<T>(`User/OfficeManagerByUser`);

  // Get Agents By OfficeManager Logged user
  GetAgentsByOfficeManager = <T>() => this.httpService.get<T>(`User/OfficeManagerAgents`);

  //Get Agents Count by Broker
  GetAgentsCountByBroker=<T>(id: number)=>this.httpService.get<T>(`User/${id}/AgentsCount`) 

  //Get Agents Count By Office Manager
  GetAgentsCountByOfficeManager=<T>(id: number)=>this.httpService.get<T>(`User/${id}/UserAgentsCountByOfficeManager`) 

  //Get All Agents
  GetAllAgents = <T>() => this.httpService.get<T>(`User/Agents`);

  GetUserbyId = <T>(id: number) => this.httpService.get<T>(`User/${id}`);

  //Get all Approved Agents
  GetAgents = <T>(id: Number, isApproved: boolean) => this.httpService.get<T>(`User/${id}/Agents` + (isApproved ? `?approved=${isApproved}` : ''));

  // //add User
  // AddUser = (payload: any) => this.httpService.post('User', payload);

  //Add system user
  AddAdmin = (payload: any) => this.httpService.post('User', payload);
  //Edit system user
  EditAdmin = (payload: any) => this.httpService.put('User', payload);
  //Delete system user
  DeleteUser = (userId: number) => this.httpService.delete(`User/${userId}`);

  //Add Company
  AddCompany = (Payload: any) => this.httpService.post('Company', Payload)
  //Add Company - Office
  AddCompanyOffice = (Payload: any) => this.httpService.post('Office', Payload)
  //Edit Company
  EditCompany = (Payload: any) => this.httpService.put('Company', Payload)
  //Edit Company - Office
  EditCompanyOffice = (Payload: any) => this.httpService.put('Office', Payload)
  //Get all companies
  GetAllCompanies = <T>() => this.httpService.get<T>('Company')

  // Get Company by Broker Id
  GetCompanyByBrokerId = (brokerId: number) => this.httpService.get(`Company/${brokerId}`);


  GetCompanyByOffficeManagerId = (officeManagerId: number) => this.httpService.get(`Company/Company/${officeManagerId}`);

  // //Get All officess
  // GetAllOffices = <T>() => this.httpService.get<T>('Office')

  //Get all offices by selected company
  GetAllOfficesByCompany = <T>(company: number) => this.httpService.get<T>(`Office/Company/${company}`)
  //Get company by id
  GetCompanyById = <T>(CompanyId: number) => this.httpService.get(`Company/GetCompany/${CompanyId}`)
  //Get company - office by id
  GetOfficeById = (officeId: number) => this.httpService.get(`Office/${officeId}`)
  //AddOfficemanager
  addmanager = (payload: any) => this.httpService.post('User', payload)

  //GetAllOfficemanagers
  GetAllOfficemanagers = <T>() => this.httpService.get<T>('officemanager')

  // Signup Agent
  Signup_Agent = (Payload: any) => this.httpService.post('Auth/Signup', Payload)

  //AddAgent
  AddAgent = (Payload: any) => this.httpService.post('User', Payload)
  //EditAgent
  EditAgent = (Payload: any) => this.httpService.put('User', Payload)
  //Approve Agent
  ApproveAgent = (userId: Number, agentId: Number) => this.httpService.put(`User/${userId}/Agent/${agentId}/Approve`, null)
  //Bulk Upload Agent
  BulkUploadAgent = (Payload: any) => this.httpService.post('User/UploadAgents', Payload)
  //AddBroker
  AddBroker = (Payload: any) => this.httpService.post('User', Payload)
  //EditBroker
  EditBroker = (Payload: any) => this.httpService.put('User', Payload)
  //editOfficemanager
  editmanager = (payload: any) => this.httpService.put('User', payload)

  //AddContact
  AddContact = (payload: any) => this.httpService.post('Contact',payload)
  //EditContact
  EditContact = (payload: any) => this.httpService.put('Contact', payload)
  //GetAllContacts
  GetAllContacts = <T>() => this.httpService.get<T>('Contact/GetAllContactsByCreatedId');
  // GetContactById
  GetContactById = <T>(ContactId: number) => this.httpService.get(`Contact/${ContactId}`)

  GetPaymentById = <T>(CustomerId: string) => this.httpService.get(`Stripe/${CustomerId}`)

  // Get Company by companyId of Brokers
  GetCompanyIdBrokers = <T>(companyId: number) => this.httpService.get(`Company/${companyId}/Brokers`)

  //Get office broker by Broker Id
  GetBrokersByBrokerId = <T>(brokerId: number) => this.httpService.get(`Office/Broker/${brokerId}`)

  // Get Broker by Office Id
  GetBrokerByOfficeId = <T>(OfficeId: number) => this.httpService.get(`Office/Broker/Office/${OfficeId}`)

  //Get All Contact Type/Id
  GetAllContactType = <T>() => this.httpService.get<T>(`Contact/GetAllBuyersByCreatedId`)

  //Add Book Tour
  AddBookTour = (payload: any) => this.httpService.post('Tour',payload)

   //Add Book Tour
   UpdateTour = (payload: any) => this.httpService.put('Tour',payload)

  // Get Search Listing by Tour Id
  GetListingByTourId = <T>(ListingId: number) => this.httpService.get(`Listing/${ListingId}/Details`)

  GetListingAvaibilityByTourId = <T>(ListingId: number) => this.httpService.get(`Listing/${ListingId}/AssetShowingAvailabilitySchedule`)

  // getTourRequestedListings = <T>() => this.httpService.get<T>(`Tour/GetTourRequestedListings`);

  // GetTourReceivedListings = <T>() => this.httpService.get<T>(`Tour/GetTourReceivedListings`);

  CancelListing = (Data: any) => this.httpService.post('Tour/CancelListing',Data);


  GetAllTourContacts = <T>() => this.httpService.get<T>(`Tour/GetAllTodayTours`);

  GetTourById = <T>(tourId: number) => this.httpService.get(`Tour/${tourId}`)

  // get agents by office id
  getAgentsByOfficeId = <T>(OfficeId: number) => this.httpService.get(`Office/Agent/Office/${OfficeId}`)

  AcceptTourByTourId =<T>(TourListingId: number) => this.httpService.put(`Tour/${TourListingId}/TourListng/Accepted`,{})

  RejectTourByTourId = <T>(TourListingId: number) => this.httpService.put(`Tour/${TourListingId}/TourListng/Rejected`, {})

  getSearchListing(requestBody: any): Observable<any> {
    const url = `/api/services/app/Search/SearchProperty`;
    return this.httpService.post_listing<any>(url, requestBody)
  }
  getSearchListingCount(requestBody: any): Observable<any> {
    const url = `/api/services/app/Search/SearchPropertyCount`;
    return this.httpService.post_listing<any>(url, requestBody)
  }
  SendMailForContact = (payload: any) => this.httpService.post('tour/SendContactToMail', payload)



  GetTodayRequestedListings = (payload: any) => this.httpService.post(`tour/GetTodayRequestedListings`,payload)
  
  GetTodayReceivedListings = (payload: any) => this.httpService.post(`tour/GetTodayReceivedListings`,payload)

  GetRequestedListingsStats = (payload:any) => this.httpService.post(`tour/GetRequestedListingsStats`,payload);
  GetReceivedListingsStats = (payload:any) => this.httpService.post(`tour/GetReceivedListingsStats`,payload);

  GetCompletedRequestedListings= (payload:any) => this.httpService.post(`tour/GetCompletedRequestedListings`,payload);
  GetReceivedListingsFeedback= <T>() => this.httpService.get<T>('tour/GetReceivedListingsFeedback')
  GetSentListingsFeedback = <T>() => this.httpService.get<T>('tour/GetRequestedListingsFeedback')

  CreateListingFeedback = (feedbackData: any) => this.httpService.post('tour/CreateListingFeedback', feedbackData)

  savedserch = (searchData: any) => this.httpService.post('SavedSearch', searchData);

  GetFeedbackByTourListingId = <T>(tourListingId: number) => this.httpService.get(`tour/GetFeedbackByTourListingId/${tourListingId}`)

  GetProperties = <T>(tourId: number) => this.httpService.get<T>(`tour/GetAlllistings/${tourId}`);
  //GetAlllistings = <T>(tourListingId: number,email:string) => this.httpService.get<T>(`tour/EmailAcceptRejectListings/${tourListingId}/${email}`);
  GetAlllistings = (payload: any) => this.httpService.post(`tour/EmailAcceptRejectListings`,payload)


  getTourRequestedListings<T>(pageIndex: number, pageSize: number, searchTerm?: any) {
    const url = `Tour/GetTourRequestedListings?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${searchTerm}`;
    return this.httpService.get<T>(url);
  }

  GetTourReceivedListings<T>(pageIndex: number, pageSize: number, searchTerm: any) {
    const url = `Tour/GetTourReceivedListings?pageIndex=${pageIndex}&pageSize=${pageSize}&searchTerm=${searchTerm}`;
    return this.httpService.get<T>(url);
  }

  GetTourRequestcalender = <T>() => this.httpService.get<T>('tour/GetTourRequestedcalendarListings')
  GetTourReceivedcalenderData = <T>() => this.httpService.get<T>('tour/GetTourReceivedCalendarListings')

  GetAllTourRequestedListings = (payload: any) => this.httpService.post(`tour/GetAllRequestedListings`,payload)

  GetAllTourReceivedListings = (payload: any) => this.httpService.post(`tour/GetAllReceivedListings`,payload)


}
