import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AcceptTourComponent } from 'src/app/app-user/tour/my-tours/accept-tour/accept-tour.component';
import { RejectTourComponent } from 'src/app/app-user/tour/my-tours/reject-tour/reject-tour.component';
import { AdminsService } from 'src/app/services/Admins/admins.service';
import { MatStepper } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';


export interface GetAllListings {
  image: string;
  tourId: number;
  listingMLSId: number;

  name?: string;
  type?: string;
  date?: string;
  timeSlot?: string;

  starttime?: string;
  endtime?: string;

  property?: string;
  status?: string;
  listingAgentName: string;

  companyName?: string;

  tourListingId: number;
  email: string;

}


@Component({
  selector: 'app-email-request',
  templateUrl: './email-request.component.html',
  styleUrls: ['./email-request.component.scss']
})
export class EmailRequestComponent implements OnInit {

  properties: GetAllListings[] = [];
  defaultImageUrl: string = 'https://images.livspace-cdn.com/w:1440/plain/https://d3gq2merok8n5r.cloudfront.net/abhinav/ond-1634120396-Obfdc/jas-1657179080-NnXAg/living-room-1657190512-fE8h4/61-1660297634-FtsXj.jpg';
  @ViewChild('stepper') stepper: MatStepper;
  message: string = '';
  messageDisplayed: boolean = false;
  messageType: string = '';

  constructor(private adminservice: AdminsService, private route: ActivatedRoute,
    private translate: TranslateService, public dialog: MatDialog) { }


  ngOnInit() {
    this.route.params.subscribe(params => {
      const tourListingId = params['tourListingId'];
      const email = params['email'];
      console.log('tourListing Id:', tourListingId);
      // Use the tourId as needed
      this.Getlistings(tourListingId, email)
    });
  }

  Getlistings(tourListingId: number, email: string): void {
    const payload = { tourListingId, email };
    this.adminservice.GetAlllistings(payload).subscribe(
      (res: GetAllListings[]) => {
        this.properties = res;
        //this.getTodayRequestedListingAddress();
      },
      (err: any) => { }
    );
  }

  async getTodayRequestedListingAddress() {
    let promises = this.properties.map(async listing => {
      let a = this.adminservice.GetListingByTourId(Number(listing.listingMLSId.toString().substr(
        listing.listingMLSId.toString().length - 4,
        listing.listingMLSId.toString().length)));
      return a
    })

    var data = await Promise.all(promises)
    data.forEach(d => {
      d.subscribe((res: any) => {
        this.properties.forEach(request => {
          if (request.listingMLSId.toString() == res.result.systemId) {
            request.property = res?.result?.assetAddress?.streetName
              + " ," + res?.result?.assetAddress?.municipality
              + " ," + res?.result?.assetAddress?.parish + ", " +
              res?.result?.assetAddress?.zone + " ," + res.result?.assetAddress?.zipcode
            request.image = res.result.assetFiles[0].physicalPath.includes('https://weumlspnonprodstgdev.blob.core.windows.net/smartrealestateimages') ? "https://weumlspnonprodstgdev.blob.core.windows.net/smartrealestateimages/" + res.result?.assetFiles?.[0]?.relativePath :
              !res.result.assetFiles[0].physicalPath.includes('https://weumlspnonprodstgdev.blob.core.windows.net/smartrealestateimages') ? "https://smartrealestate-api-dev.azurewebsites.net/" + res.result?.assetFiles?.[0]?.relativePath : this.defaultImageUrl;

          }
        })
      })
    });
  }


  getStatusClasses(status: string): string {
    switch (status) {
      case 'Pending':
        return 'pending-class';
      case 'Confirmed':
        return 'confirmed-class';
      case 'Rejected':
        return 'rejected-class';
      case 'Active':
        return 'confirmed-class';
      case 'Cancelled':
        return 'rejected-class';
      default:
        return '';
    }
  }


  AcceptTour(rowData: GetAllListings) {
    const dialogRef = this.dialog.open(AcceptTourComponent, {
      panelClass: "custom-modal-accept",
      data: rowData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'accepted') {
        this.translate.get('Your tour request has been accepted! You may now safely close this window.')
          .subscribe((translatedMessage: string) => {
            this.message = translatedMessage;
            this.messageType = 'accepted';
            this.messageDisplayed = true;
        });
      }
    });
  }

  RejectTour(rowData: GetAllListings) {
    const dialogRef = this.dialog.open(RejectTourComponent, {
      panelClass: "custom-modal-accept",
      data: rowData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'rejected') {
        this.translate.get('Your tour request has been rejected! you may now safely close this window.')
        .subscribe((translatedMessage: string) => {
        this.message = translatedMessage;
        this.messageType = 'rejected';
        this.messageDisplayed = true;
       });
      }
    });
  }
  formatListingAgentName(name: string): string {
    return name.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}
